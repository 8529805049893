import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GrecaImage from '../src/assets/pagina_il_cielofondo_greca.png';
import './index.css';

// Importa los componentes que usarás en las rutas
import Home from './components/home';
import Header from './components/header';
import Events from './components/events';
import Menu from './components/menu';

function App() {
  
  return (
    <Router>
      <div className=" h-screen w-screen" style={{
        height:'100vh'
      }}>
        <img
          src={GrecaImage}
          className="w-full bg-greca"
          style={{
            height: '3rem'
          }}
          alt="Logo"
        />  
        <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/experience" element={<Home />} />
            <Route path="/events" element={<Events />} />
            <Route path="/menu" element={<Menu />} />
          </Routes>
      </div>
    </Router>
  );
}

export default App;