import Reservations from "./reservations";

import GrecaImage from '../assets/pagina_il_cielofondo_greca.png';

import EventDescription from '../assets/EventsDescription.jpg'
import First from '../assets/OneSlide.jpg';
import Second from '../assets/SecondSlide.jpg';
import Third from '../assets/ThirdSlide.jpg';
import Fourth from '../assets/FourthSlide.jpg';
import Fifth from '../assets/FifthSlide.jpg';
import Six from '../assets/SixSlide.jpg';


function EventsUnforgetable () {
    return (
        <div>
    <section id="our-kitchen" className=" text-white py-12 flex items-center justify-center" style={{
            backgroundColor:"rgb(0 29 99)"
        }}>
    <div className="container mx-auto px-16 flex flex-col md:flex-row items-center">
      {/* Imagen */}

      {/* Texto */}
      <div className="md:w-1/2 mt-8 md:mt-0 md:ml-8 px">
      <h2 className="text-4xl  font-mono font-bold text-white">Eventos </h2>
        <h2 className="font-calligraphy text-4xl font-bold text-yellow-400 ">inolvidables</h2>
        <p className="mt-4 font-bold font-louis text-2xl">
          ¿Quieres sorprender y sorprenderte con una fiesta especial e inolvidable? Te ofrecemos la mejor de las celebraciones, adaptándonos a tus necesidades. 
          Queremos emocionar y emocionarte, dándole a tu evento ese "toque especial" que lo transformará en mágico y diferente, y que conseguirá un recuerdo imborrable.
          Convertimos tus ideas, proyectos y sueños en realidad.
        </p>
      </div>
      <div className="md:w-1/2">
      <img
          src={EventDescription}
          className="w-full mt-5"
          alt="Logo"
        /> 
      </div>
    </div>

  </section>
  <section className="bg-kitchen"  style={{
            backgroundColor:"rgb(0 29 99)"
        }}>
  <div class="container mx-auto py-8" >
    <h1 class="text-4xl font-bold font-calligraphy text-center mb-8 text-white">Galeria</h1>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 px-4">
        <div class="relative rounded-lg overflow-hidden shadow-lg">
            <img src={First} alt="Image 1" class="w-full h-full object-cover"/>
        </div>
        <div class="relative rounded-lg overflow-hidden shadow-lg">
            <img src={Second} alt="Image 2" class="w-full h-full object-cover"/>
        </div>
        <div class="relative rounded-lg overflow-hidden shadow-lg">
            <img src={Third} alt="Image 3" class="w-full h-full object-cover"/>
        </div>
        <div class="relative rounded-lg overflow-hidden shadow-lg">
            <img src={Fourth} alt="Image 4" class="w-full h-full object-cover"/>
        </div>
        <div class="relative rounded-lg overflow-hidden shadow-lg">
            <img src={Fifth} alt="Image 5" class="w-full h-full object-cover"/>
        </div>
        <div class="relative rounded-lg overflow-hidden shadow-lg">
            <img src={Six} alt="Image 6" class="w-full h-full object-cover"/>
        </div>
    </div>
</div>
</section>
<div className=' bg-greca'>
        <img
          src={GrecaImage}
          className="w-full"
          style={{
            height: '3rem'
          }}
          alt="Logo"
        /></div>
<Reservations></Reservations>
      </div>
    )
}

export default EventsUnforgetable;