
import PataSaladaImage from "../assets/PataSalada.jpg"

function PataSalada () {
    return (
        <section id="our-kitchen" className=" text-white py-12 flex items-center justify-center" style={{
            backgroundColor:"#0a0909"
        }}>
    <div className="container flex flex-col md:flex-row items-center" >
    {/* Imagen */}
    <div className="w-full h-full ">
    <img
        src={PataSaladaImage}
        className="w-full"
        alt="Logo"

        /> 
    </div>
  {/* Texto */}
  <div className="md:w-1/2 mt-8 md:mt-0 md:ml-8 px-8">
  <h2 className="text-4xl  text-yellow-400 font-mono font-bold">Sentirás la vibra</h2>
    <h2 className="font-calligraphy mt-2 text-6xl font-bold text-yellow-400 ">Pata Salada</h2>
    <p className="mt-4 font-louis font-bold text-2xl">
      Se basa en el uso de productos frescos de Mazatlan, promoviendo la amplia gastronomía de la región que se ofrece en nuestro menú y mostrando la autenticidad de nuestros platillos caseros para satisfacer todos los paladares.
    </p>
  </div>
</div>
</section>
    )
}

export default PataSalada;

