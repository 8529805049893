
import GrecaImage from '../assets/pagina_il_cielofondo_greca.png';
import Kitchen from './kitchen';
import Reservations from './reservations'
import MenuOnly from './menuOnly';
import PataSalada from './pataSalada';
import DesImage from "../assets/IMG_9276.jpg"
import BrunImage from "../assets/IMG_9868.jpg"
import DinnImage from "../assets/IMG_9830.jpg"

function Home () {
    return (
        <div>
        <section id="experience" 
        className="bg-experiences bg-gray-100 py-12 h-screen">
        <div className="container mx-auto px-4 h-full flex items-center justify-center">
            <div className="text-white p-8 rounded text-center">
            <h2 className="text-4xl font-mono font-bold ">
                El sabor mazatleco en la <span className=" text-4xl font-calligraphy">Plazuela Machado</span>
            </h2>
            </div>
        </div>
        </section>
        <div className=' bg-greca'><img
          src={GrecaImage}
          className="w-full"
          style={{
            height: '3rem'
          }}
          alt="Greca"
        /></div>

        <Kitchen></Kitchen>
        <MenuOnly></MenuOnly>
        <section>
            <PataSalada></PataSalada>
        </section>
        <Reservations></Reservations>
      </div>
    )
}

export default Home;