import EventsUnforgetable from "./eventsUnforgetable";
import HeaderEvents from "./headerEvents";
import GrecaImage from '../assets/pagina_il_cielofondo_greca.png';


function Events () {
    return (
        <div className="w-screen h-screen">
            <HeaderEvents></HeaderEvents>
            <div className=' bg-greca'><img
          src={GrecaImage}
          className="w-full"
          style={{
            height: '3rem'
          }}
          alt="Greca"
        /></div>
            <EventsUnforgetable></EventsUnforgetable>
        </div>
        )}

export default Events;