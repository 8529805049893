function HeaderEvents () {
    return (
        <div>
            <section id="experience" 
            className="bg-events-header bg-gray-100 py-12 h-screen">
                <div className="container mx-auto px-4 h-full flex items-center justify-center">
                    <div className="text-white p-8 rounded text-center">
                        <h2 className="text-3xl font-mono font-bold ">
                            Vive tu <span className="font-calligraphy">Evento Con Nosotros</span>
                        </h2>
                    </div>
                </div>
            </section>
        </div>
        )}

export default HeaderEvents;