import KitchenImage from "../assets/kitchen.jpg"



function Kitchen () {
    return (
        <div>
    <section id="our-kitchen" className="bg-kitchen text-white py-12 flex items-center justify-center">
    <div className="container mx-auto px-16 flex flex-col md:flex-row items-center">
      {/* Imagen */}
      <div className="md:w-1/2">
      <img
          src={KitchenImage}
          className="w-full"
          alt="Logo"
        /> 
      </div>
      {/* Texto */}
      <div className="md:w-1/2 mt-8 md:mt-0 md:ml-8 px">
      <h2 className="text-4xl font-mono font-bold text-white">Nuestra </h2>
        <h2 className="font-calligraphy text-7xl font-bold text-yellow-400 ">Cocina</h2>
        <p className="mt-4 font-bold text-2xl font-louis">
          Se basa en el uso de productos frescos de Mazatlán, promoviendo la amplia gastronomía de la región que se ofrece en nuestro menú y mostrando la autenticidad de nuestros platillos caseros para satisfacer todos los paladares.
        </p>
      </div>
    </div>
  </section>
      </div>
    )
}

export default Kitchen;