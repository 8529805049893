import React, { useState } from 'react';
import facebook from "../assets/facebook.png";
import tiktok from "../assets/tiktok.png";
import instagram from "../assets/instagram.png";
import zoedev from "../assets/zoedev.jpeg";

// Function to check if a date is a holiday
const isHoliday = (date) => {
    const holidays = ['2024-12-24', '2024-12-25', '2024-12-31', '2025-01-01']; // Add more holidays as needed
    return holidays.includes(date);
};

function Reservations() {
    const [formData, setFormData] = useState({
        name: '',
        numberOfPeople: '',
        date: '',
        time: '',
        phone: '',
        area: 'Interior',
    });

    const [errorMessage, setErrorMessage] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        // Remove error message when user starts typing again
        setErrorMessage('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate phone number
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(formData.phone)) {
            setErrorMessage('Por favor, ingrese un número de teléfono válido (10 dígitos).');
            return;
        }

        // Validate time (no reservations after 8:30 PM)
        if (formData.time > '20:30') {
            setErrorMessage('Las reservaciones sólo se permiten hasta las 8:30 PM.');
            return;
        }

        // Validate date (no reservations on holidays or before today)
        const currentDate = new Date().toISOString().split('T')[0]; // Get current date in 'YYYY-MM-DD' format
        if (formData.date < currentDate) {
            setErrorMessage('No se permiten reservaciones para fechas anteriores a hoy.');
            return;
        }

        if (isHoliday(formData.date)) {
            setErrorMessage('No se permiten reservaciones en días festivos.');
            return;
        }

        // Validate number of people (limit to 15)
        if (parseInt(formData.numberOfPeople, 10) > 15) {
            setShowTooltip(true);
            return;
        }

        const message = `Hola, me gustaría hacer una reservación con los siguientes detalles:
- Nombre: ${formData.name}
- Número de personas: ${formData.numberOfPeople}
- Fecha: ${formData.date}
- Hora: ${formData.time}
- Teléfono: ${formData.phone}
- Área: ${formData.area}`;

        const whatsappNumber = '+526676965417'; // Replace with the desired WhatsApp number
        const whatsappURL = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`;

        window.open(whatsappURL, '_blank');

        setFormData({
            name: '',
            numberOfPeople: '',
            date: '',
            time: '',
            phone: '',
            area: 'Interior',
        });
        setShowTooltip(false);
    };

    return (
        <section id="reservations" style={{ backgroundColor: "#04296d" }}>
            <div className="container mx-auto px-4 py-14 text-center h-screen sm:h-auto">                  
                    <button
                        type="button"
                        onClick={() => {
                            window.open('https://widget.riservi.com/allies/restaurant-widget/el-cielo-del-pacifico-4yW')
                        }}
                        className="bg-yellow-300 h-16 w-60 text-black px-4 py-2 rounded-md hover:bg-blue-900 hover:text-white font-calligraphy"
                    >
                        <h6 className=' text-4xl'>Reservaciones</h6>
                    </button>

                <p className="mt-10 text-white font-bold">
                    Lunes a Domingo de 8:30am a 10:30pm • Sixto Osuna 518, Centro, 82000 Mazatlán, Sin. • +52 667 696 5417
                </p>
                <div className="flex mt-10 justify-center items-center lg:justify-between">
                <div className="flex items-center w-full justify-center">
                    <a href="https://www.facebook.com/Elcielodelpacifico?mibextid=LQQJ4d" className="mr-6">
                        <img src={facebook} alt="Facebook" style={{ width: '2rem', height: '2rem' }} />
                    </a>
                    <a href="https://www.instagram.com/elcielodelpacifico/" className="mr-6 cursor-pointer text-gray-600">
                        <img src={instagram} alt="Instagram" style={{ width: '2.1rem', height: '2.15rem' }} />
                    </a>
                    <a href="https://www.tiktok.com/@elcielorestaurantbar.mzt?_t=8eZ5lLawbYb&_r=1" className="cursor-pointer text-gray-600">
                        <img src={tiktok} alt="TikTok" style={{ width: '2.1rem', height: '2.15rem' }} />
                    </a>
                </div>
            </div>
            <div className="flex items-center mt-6 justify-center space-x-2">
                <span className="text-lg font-semibold text-black">Powered by</span>
                <img
                    src={zoedev}
                    alt="Zoedev Logo"
                    className="w-12 h-12 rounded-full shadow-lg"
                />
            </div>
            </div>

        </section>
    );
}

export default Reservations;
