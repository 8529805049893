import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/logo.png';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const location = useLocation();

  const handleRedirect = () => {
    window.open("https://widget.riservi.com/allies/restaurant-widget/el-cielo-del-pacifico-4yW", "_blank");
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleScroll = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isOpen]);

  const isMenuOrReservationsPage =
    location.pathname === '/menu' || location.pathname === '/reservations';

  return (
    <header className="absolute top-0 left-0 w-full bg-transparent text-white py-4 md:py-8">
      <div className="flex items-center justify-between px-4 md:px-8">
        <button
          className="text-white md:hidden"
          onClick={() => setIsOpen(!isOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
            ></path>
          </svg>
        </button>
      </div>
      <nav
        ref={menuRef}
        className={`flex flex-col font-louis mt-2 bg-gradient-to-b from-indigo-950 to-transparent text-white text-center bg-opacity-50 md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-8 text-xl font-bold transition-all duration-500 ease-in-out ${
          isOpen ? `max-h-screen opacity-100 ${isMenuOrReservationsPage ? 'bg-opacity-25 backdrop-filter backdrop-blur-md' : ''}` : 'max-h-0 opacity-0'
        } md:max-h-full md:opacity-100 overflow-hidden font-louis text-3xl`}
      >
        <img
          src={Logo}
          className={`h-12 md:h-28 text-center ${isOpen ? 'mt-5' : ''}`}
          alt="Logo"
        />
        <Link to="/experience" className="hover:underline text-yellow-400">
          <p className='font-louis text-2xl px-3'>Experiencia</p>
        </Link>
        <Link to="/menu" className="hover:underline text-yellow-400">
          <p className='font-louis text-2xl'>Menú</p>
        </Link>
        <Link to="/events" className="hover:underline text-yellow-400">
          <p className='font-louis text-2xl'>Eventos</p>
        </Link>
        <p onClick={handleRedirect} className={`font-louis text-2xl cursor-pointer hover:underline text-yellow-400 ${isOpen ? 'mb-7' : ''}`}>
          Reservaciones
        </p>
      </nav>
    </header>
  );
}

export default Header;

